
$().ready(() => {
	$("a[href^='#']").not("[href$='#'], .noscr").on('click', function(e) {
		e.preventDefault();
		try {
			let t = this.hash;
			let h = $(t).offset().top;
			return $("html,body").animate({scrollTop: h}, 800);
		} catch (err) {}
	});

	if ($('.mainvisual').length > 0) {
		setInterval(() => {
			let now = parseInt($('.actv').index(), 10);
			let next;
			if (now < 2) {
				next = now + 1;
			} else {
				next = 0;
			}

			$('.mainvisual .actv').removeClass('actv');
			$('.mainvisual li').eq(next).addClass('actv');
		}, 5000);
	}

	if ($(window).width() > 768) {
		$('.moviebox').colorbox({
			href: $(this).attr('href'),
			width: '1120px',
			height: '630px',
			iframe: true
		});

		$(".trigger").on('click', (e) => {
			e.preventDefault();
		});

		$('.trigger').on('mouseenter', (e) => {
			e.preventDefault();

			$('.navi_bg').addClass('on');
			$('.subnavi_wrapper').fadeIn();
		});

		$('.subnavi_wrapper').on('mouseleave', (e) => {
			e.preventDefault();

			$(".navi_bg").removeClass("on");
            $(".subnavi_wrapper").fadeOut();
		});
	} else {
		let w = Math.ceil($(window).width() * 0.9);
		let h = Math.ceil(w * 0.5625);

		$('.moviebox').colorbox({
			href: $(this).attr('href'),
			width: w + 'px',
			height: h + 'px',
			iframe: true
		});

		$('.trigger').on('click', (e) => {
			e.preventDefault();

			$('#sp_menu .subnavi').slideToggle();
		});
	}

	$('#menu a').on('click', (e) => {
		e.preventDefault();

		$('#sp_menu').addClass('on');
		$('#overlay').fadeIn();
	});

	$('#sp_menu .close a').on('click', (e) => {
		e.preventDefault();

		$('#sp_menu').removeClass('on');
		$('#overlay').fadeOut();
	});

	$('#news .item a').on('click', (e) => {
		e.preventDefault();
	});

	$('#home .js-appear').each(function() {
		$(this).on('inview', function() {
			$(this).addClass('on');
		});
	});
});